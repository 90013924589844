import { Network } from 'apps/bridge/src/types/Network';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const l2ChainID = parseInt(publicRuntimeConfig.l2ChainID);

const CHAIN_ID_TO_NETWORK: Record<number, Network> = {
  1450: 'chain',
  1432: 'chain-sepolia',
};

export function getL2NetworkForChainEnv() {
  return CHAIN_ID_TO_NETWORK[l2ChainID];
}
