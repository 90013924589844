/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import Link from 'next/link';
import { useRouter } from 'next/router';
import Grid from '@mui/joy/Grid';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';

type BridgeContainerProps = {
  children: JSX.Element;
};

const tabs = [
  {
    text: 'Deposit',
    link: '/deposit',
  },
  {
    text: 'Withdraw',
    link: '/withdraw',
  },
];

export function BridgeContainer({ children }: BridgeContainerProps) {
  // Use the `router` object from the `useRouter` hook
  const router = useRouter();

  // Now you can use `router` directly
  const isActiveTab = (link: string) => router.pathname.includes(link);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid xs={12}>
          <Sheet
            variant="outlined"
            sx={{
              p: 2,
              borderRadius: 'sm',
              minHeight: '86vh',
            }}
          >
            <Typography level="h2" component="h2" sx={{ mb: 2 }}>
              BRIDGE TO CHAIN
            </Typography>
            <Box sx={{ mb: 4, display: 'flex', justifyContent: 'start', gap: 2 }}>
              {tabs.map(({ text, link }) => (
                <Link key={link} href={link} passHref>
                  <Box sx={{ textAlign: 'center', width: { xs: '1/2', sm: 'auto' } }}>
                    <Button
                      variant="outlined"
                      onClick={(e) => {
                        e.preventDefault();
                        router.push(link);
                      }}
                    >
                      {text}
                    </Button>
                  </Box>
                </Link>
              ))}
            </Box>
            <Divider sx={{ mb: 2 }} />
            {children}
          </Sheet>
        </Grid>
      </Grid>
    </Box>
  );
}
